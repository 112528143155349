// styles
import styles from './style.module.scss';

// hooks
import { useModal } from '@contexts/modalContext';
import { useAuth } from '@contexts/AuthContext';
import { useEffect, useState } from 'react';
import Loading from '@pages/Loading';
import { NavLink, Navigate } from 'react-router-dom';


const Membership = ({ variant }) => {
    const { handleOpen } = useModal();
    const { currentUser } = useAuth();
    const [textStyle, setTextStyle] = useState(null);
    useEffect(() => {
        const updatedTextStyle = currentUser.plan_id === 1 ? styles.GlowingTextGray :
            (currentUser.plan_id === 2 ? styles.GlowingTextBlue :
                (currentUser.plan_id === 3 ? styles.GlowingTextGreen :
                    styles.GlowingText));

        setTextStyle(updatedTextStyle);
    }, [currentUser]);

    return currentUser ? (
        variant === 'default'
            ?
            <div className={`${styles.container} widget_body card`}>
                <div className="d-flex flex-column g-8">
                    <div className="d-flex flex-row g-8">
                        <h2>You're {currentUser.plan.plan_name} </h2>
                    </div>
                    <div className="d-flex flex-row g-8">
                        {currentUser.plan.tokens === 0 ?
                            <h2>You have <span className={textStyle}>no active subscription</span></h2>
                            :
                            <h1>You will receive <span className={textStyle}>{currentUser.plan.tokens.toLocaleString()}</span> tokens each month.</h1>
                        }
                    </div>
                </div>
            </div>
            :
            <div className={`${styles.container} widget_body card`}>
                <div className="d-flex flex-column g-8">
                    <div className="d-flex flex-row g-8">
                        <h2>{currentUser.plan.tokens === 0 ?
                            "You have no subscription" : "Need extra tokens?"}</h2>
                    </div>
                    <div className={`d-flex flex-row g-8 ${styles.grid}`}>
                        {currentUser.plan.tokens === 0 ?
                            (
                                <div className={`d-flex flex-row g-8 ${styles.grid}`} style={{ textAlign: 'center' }}>
                                    <NavLink to={'/subscriptions'} className={`${styles.addcard} card bordered`}>
                                        <h3 className={styles.GlowingTextGreen}>Start a subscription</h3>
                                    </NavLink>
                                </div>
                            )
                            :
                            (
                                <>
                                    <button onClick={() => handleOpen('beta')} className={`${styles.addcard} card bordered`}>
                                        <h3 className={styles.GlowingTextGreen}>+ 1,000,000</h3>
                                    </button>
                                    <button onClick={() => handleOpen('beta')} className={`${styles.addcard} card bordered`}>
                                        <h3 className={styles.GlowingTextGreen}>+ 500,000</h3>
                                    </button>
                                    <button onClick={() => handleOpen('beta')} className={`${styles.addcard} card bordered`}>
                                        <h3 className={styles.GlowingTextGreen}>+ 100,000</h3>
                                    </button>
                                </>
                            )}
                    </div>
                </div>
            </div>
    )
        : <Loading />
}

export default Membership