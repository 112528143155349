// styles
import styles from './style.module.scss';

// hooks
import { useAuth } from '@contexts/AuthContext';
import { useEffect } from 'react';

import Loading from '@pages/Loading';
import { getUsers } from 'api/apiHandler';
import { useWindowSize } from 'react-use';
import { useModal } from '@contexts/modalContext';

const UsersList = () => {
    const { currentUser, users, setUsers } = useAuth();
    const { width } = useWindowSize();
    const { handleOpen, setModalData } = useModal();

    const isMobile = width <= 768;

    useEffect(() => {
        if (!users) {
            getUsers().then(response => {
                console.log("Retrieved users: ", response.system)
                setUsers(response.system);
            }).catch(error => {
                console.error("Error retrieving Users.");
            });
        }
    }, [users, setUsers]);

    return currentUser ? (

        !users ?
            <Loading />
            :
            <div className='d-flex flex-column g-16'>
                {
                    users.users.map((user, index) => (
                        isMobile ?
                            <div key={user.id} className='d-flex flex-row g-8 align-items-stretch'>
                                <button className={`card ${styles.containerMob} flex-grow-1`} style={{ maxWidth: '50%' }}>
                                    <span className={`${styles.num} num d-flex flex-center`}>{user.id + 1}</span>
                                    <h3 className='text-overflow' style={user.func === "SYSOPERATOR" ? { color: 'var(--red)', width: '70%' } : { width: '70%' }}>{user.name}</h3>
                                </button>
                                <button className={`card ${styles.squareMob}`}><h4 style={{ padding: '0px 24px' }}>Ban user</h4></button>
                                <button  onClick={() => {setModalData(user); handleOpen('give')}}  className={`card ${styles.squareMob}`}><h4 style={{ padding: '0px 24px' }}>Give Tokens</h4></button>
                            </div>
                            :
                            <div key={user.id} className='d-flex flex-row g-16 align-items-stretch'>
                                <button onClick={() => handleOpen('beta')} className={`card ${styles.container} flex-grow-1`} style={{ justifyContent: 'space-between' }}>
                                    <div className={`${styles.container} g-8`}>
                                        <span className={`${styles.num} num d-flex flex-center`}>{user.id}</span>
                                        <h2 style={!user.is_active ? { color: 'var(--label)' } : {}}>{user.name}</h2>
                                        {user.id === 0
                                            ?
                                            <p>(This is the system's superuser from wich give actions are processed)</p>
                                            : null
                                        }
                                    </div>
                                    <div className='d-flex flex-row g-16'>
                                        <h3 style={!user.is_active ? { color: 'var(--label)' } : {}}>{user.email}</h3> |
                                        <h3 style={!user.is_active ? { color: 'var(--label)' } : {}}>{user.is_active ? 'active' : 'disabled'}</h3> |
                                        <h3 style={!user.is_active ? { color: 'var(--label)' } : (user.func === "SYSOPERATOR" ? { color: 'var(--red)' } : {})}>{user.func}</h3> |
                                        <h3 style={!user.is_active ? { color: 'var(--label)' } : {}}>{users.wallets.find(item => item.user_id === user.id).tokens.toLocaleString()} tokens</h3>
                                    </div>
                                </button>
                                {user.id !== 0 &&
                                    <>
                                        <button onClick={() => handleOpen('beta')} className={`card ${styles.square}`}><h3 style={{ padding: '0px 24px' }}>Ban user</h3></button>
                                        <button onClick={() => {setModalData(user); handleOpen('give')}} className={`card ${styles.square}`}><h3 style={{ padding: '0px 24px' }}>Give Tokens</h3></button>
                                    </>
                                }
                            </div>
                    ))
                }
            </div>




    )
        : <Loading />
}

export default UsersList