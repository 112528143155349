// styles
import styles from './style.module.scss';
import Tilt from 'react-parallax-tilt';

// hooks
import { useModal } from '@contexts/modalContext';
import { useAuth } from '@contexts/AuthContext';
import { useEffect, useState, useRef } from 'react';
import Loading from '@pages/Loading';
import { NavLink, Navigate } from 'react-router-dom';

import green from '@assets/offer-g.png';
import gray from '@assets/offer-d.png';
import purple from '@assets/offer-p.png';
import blue from '@assets/offer-b.png';
import Package from '@widgets/Package';
import { createSubscription } from 'api/apiHandler';

// Icons
import { GiCheckMark } from 'react-icons/gi';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '@components/CheckoutForm';
import ScrollContainer from '@components/ScrollContainer';
import SuccessAnimation from 'actually-accessible-react-success-animation';
import CenteredWrapper from '@components/CenteredWrapper';

const stripePromise = loadStripe('pk_live_51OWO8SHYzbRPFUSxJ3Hn3UcwniyQgVbaLFD2sD1wPdw99vX4YHEXeHHuTe5bSukvtmhT4OktswnU1ofC5Xr52Tc000PRJpjvwa');

const FinishCheck = ({ sub, select, features, verified, setRunConfetti, setRecycleConfetti, alreadyVerified, paymentError, planId }) => {
    const { handleOpen, setModalData } = useModal();
    const { currentUser } = useAuth();
    const [textStyle, setTextStyle] = useState(null);
    const [elementHeight, setElementHeight] = useState(0)
    const [elementWidth, setElementWidth] = useState(0)
    const elementRef = useRef(null)

    const [stripeOptions, setStripeOptions] = useState(null);

    const [visibleFeatures, setVisibleFeatures] = useState(0);
    const [paymentAccepted, setPaymentAccepted] = useState(false);

    const expandableRef = useRef(null);


    useEffect(() => {
        if (paymentAccepted) {
            const timer = setInterval(() => {
                setVisibleFeatures((prev) => {
                    if (prev < features.FORMATTED.length) {
                        const newPrev = prev + 1;

                        // Adjust max-height for every new feature immediately after it's shown
                        requestAnimationFrame(() => {
                            if (expandableRef.current) {
                                const newHeight = expandableRef.current.scrollHeight;
                                expandableRef.current.style.maxHeight = `${newHeight}px`;
                            }
                        });

                        return newPrev;
                    }
                    clearInterval(timer);
                    return prev;
                });
            }, 3000);
            return () => clearInterval(timer);
        }
    }, [paymentAccepted]);


    useEffect(() => {
        if (verified) {
            setRunConfetti(true);
            setTimeout(() => {
                setPaymentAccepted(true);
            }, 1000);
            setTimeout(() => {
                setRecycleConfetti(false);
            }, 5000);
        }
    }, [verified]);

    useEffect(() => {
        const updatedTextStyle = styles.GlowingText;

        setTextStyle(updatedTextStyle);
    }, [currentUser]);

    useEffect(() => {
        setElementHeight(elementRef.current.clientHeight);
        setElementWidth(elementRef.current.clientWidth);
    })

    return currentUser ? (
        <div
            ref={elementRef}
            className={`${styles.container} widget_body card`}
            style={{
                boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
            }}
        >

            <div className="d-flex flex-column g-8">
                <div className="d-flex flex-row justify-content-between align-items-center g-8">
                    <h2 style={{ fontSize: '1.5em' }}>Payment complete</h2>
                    <NavLink to={'/'}>
                        <h4 className='d-flex flex-row align-items-center g-8' >Go to home<i className='icon icon-chevron-right'></i></h4>
                    </NavLink>
                </div>
            </div>

            <div
                className="d-flex flex-row scroll-y g-8"
                style={{ width: '100%', height: '100%' }}>



                <div className='d-flex flex-column align-items-center' style={{ width: elementWidth - (planId == null ? 0 : 350), height: '90%' }}>
                    <CenteredWrapper>
                        <div className={`${styles.expandable} d-flex flex-column align-items-center`} ref={expandableRef} >
                            {alreadyVerified && <h1 style={{ color: 'var(--green)', textAlign: 'center', padding: 5 }}>Your payment is confirmed in our systems.</h1>}
                            {paymentAccepted && <h1 className={`${styles.fadeIn}`} style={{ color: 'var(--green)', textAlign: 'center', padding: 5 }}>Thank you, your payment has been accepted &nbsp;<GiCheckMark /></h1>}
                            {paymentAccepted && (
                                features.FORMATTED.slice(0, visibleFeatures).map((feature, index) => (
                                    index === 0 ?
                                        (
                                            <h2 key={index} className={`${styles.fadeIn}`} style={{ animationDelay: `${1}s`, marginTop: 10, fontWeight: 'lighter' }}>
                                                {feature}
                                            </h2>
                                        )
                                        :
                                        (
                                            <h2 key={index} className={`${styles.fadeIn}`} style={{ animationDelay: `${1}s`, marginTop: 10, fontWeight: 'lighter' }}>
                                                {feature}
                                            </h2>
                                        )
                                ))
                            )}

                            {paymentError && (
                                <h1 style={{ color: 'var(--gray)', textAlign: 'center', padding: 5 }}>Cannot find a trace of this payment</h1>
                            )}
                        </div>
                    </CenteredWrapper>
                </div>

                <div
                    className={`${styles.container} widget_body card`}
                    style={{
                        borderRadius: '5px',
                        position: 'relative',  // This ensures inner content can be positioned relative to this card
                        flexShrink: 0,  // Prevent this div from shrinking below its natural size
                    }}>
                    {/* Right part */}
                    {planId != null &&
                    (
                        <Package sub={sub} features={features} />
                    )}
                </div>
            </div>




        </div>


    )
        : <Loading />
}


export default FinishCheck