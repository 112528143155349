import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useState } from 'react';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsLoading(false);
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://gpt.insane.live/finishCheckout",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setErrorMessage(result.error.message);
      setIsLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form style={{ width: '100%', padding: '8px' }}>
      <PaymentElement />
      {errorMessage && <p style={{ color: "var(--red)", marginTop: '8px' }}>{errorMessage}</p>}
      {!isLoading && (
        <button onClick={(e) => handleSubmit(e)} style={{ backgroundColor: "#282828", width: "100%", height: '60px', borderRadius: '5px', marginTop: "16px" }}>
          <div className={`d-flex flex-row justify-content-center align-items-center g-8 p-16`}>
            <h3>Confirm subscription</h3>
          </div>
        </button>
      )}
    </form>
  );
};

export default CheckoutForm;