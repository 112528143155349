// styles
import styles from './style.module.scss';

// hooks
import { useAuth } from '@contexts/AuthContext';
import { useEffect } from 'react';

import Loading from '@pages/Loading';
import ScrollContainer from '@components/ScrollContainer';
import NotificationsListItem from '@widgets/NotificationsListItem';
import { getNotifications } from 'api/apiHandler';


const NotificationsList = () => {
    const { currentUser, notifications, setNotifications } = useAuth();
    useEffect(() => {
        if (!notifications) {
            getNotifications().then(response => {
                console.log("Retrieved notifications: ", response.notifications)
                setNotifications(response.notifications);
            }).catch(error => {
                console.error("Error retrieving notifications.");
            });
        }
        currentUser.notifications_read = 1;
    }, [notifications, setNotifications, currentUser]);

    return currentUser ? (
        <div className={`${styles.container} widget_body card`}>

            {!notifications ?
                <Loading />
                :
                <ScrollContainer height={100} bg="widget-bg">

                    <div className="track border-12" style={{ padding: '0 16px' }}>
                        <div className="d-flex flex-column g-8 pb-16 scroll-y">
                            {notifications.map((notif, index) => (
                                <NotificationsListItem
                                    key={index}
                                    index={index}
                                    title={notif.title}
                                    description={notif.description}
                                    author={notif.created_by}
                                    relevant_link={notif.relevant_link}
                                    type={notif.type}
                                    priority={notif.priority}
                                    created_at={notif.created_at}
                                />
                            ))}

                        </div>
                    </div>
                </ScrollContainer>
            }
        </div>
    )
        : <Loading />
}

export default NotificationsList