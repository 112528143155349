const CenteredWrapper = ({ children, height }) => {
    const centerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: height ? height : '100vh'
    };

    return <div style={centerStyle}>{children}</div>;
};

export default CenteredWrapper