// ProtectedRoutes.js
import { Navigate } from 'react-router-dom';
import { useAuth } from '@contexts/AuthContext';
import Loading from '@pages/Loading';

export const PrivateRoute = ({ children }) => {
  const { currentUser, isLoading } = useAuth();
  if (!currentUser && !isLoading) return <Navigate to="/sign-in" />;
  return children;
};

export const PublicRoute = ({ children }) => {
  const { currentUser, isLoading } = useAuth();
  if (currentUser && !isLoading) return <Navigate to="/" />;
  return children;
};

export const AdminRoute = ({ children }) => {
  const { currentUser, isLoading } = useAuth();

  // Check if user's data is still loading
  if (isLoading) return <Loading />;

  // Check if user is authenticated
  if (!currentUser) return <Navigate to="/sign-in" />;

  // Check if user has an admin role locally
  if (currentUser.func !== 'SYSOPERATOR') {
    return <Navigate to="/" />;
  }

  if (!currentUser.base) {
    return <Navigate to="/" />;
  }

  if (currentUser.base !== 'tU9bDgYx7KVZuzo') {
    return <Navigate to="/" />;
  }

  return children;
};