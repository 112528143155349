// styles
import styles from './style.module.scss';
import Tilt from 'react-parallax-tilt';

// hooks
import { useModal } from '@contexts/modalContext';
import { useAuth } from '@contexts/AuthContext';
import { useEffect, useState } from 'react';
import Loading from '@pages/Loading';
import { NavLink, Navigate } from 'react-router-dom';


const SubPackage = ({ id, name, tokens, price, select }) => {
    const { handleOpen, setModalData } = useModal();
    const { currentUser } = useAuth();
    const [textStyle, setTextStyle] = useState(null);
    useEffect(() => {
        const updatedTextStyle = id === 1 ? styles.GlowingTextGray :
            (id === 2 ? styles.GlowingTextBlue :
                (id === 3 ? styles.GlowingTextGreen :
                    styles.GlowingText));

        setTextStyle(updatedTextStyle);
    }, [currentUser]);

    return currentUser ? (
        <Tilt scale={0.95}tiltAxis={'y'} height={'100%'} style={{ width: '100%', height: '100%'}}>
            <button onClick={() => select({id, name, tokens, price})} className={`${styles.container} widget_body card ${currentUser.plan.plan_name == name && 'bordered'}`} style={currentUser.plan.plan_name === name ? {backgroundColor: '#252525', width: '100%', height: '100%', position: 'relative'} : { width: '100%', height: '100%', position: 'relative' }}>
                <div className="d-flex flex-column g-8">
                    <div className="d-flex flex-row g-8">
                        <h2 style={{ fontSize: '1.5em' }}>{name}</h2>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center g-8" style={{ width: '100%', height: '70%' }}>
                    <div className="d-flex flex-row justify-content-center align-items-center g-8">
                        <h2 style={{ fontSize: '3em' }} className={`${textStyle}`}>{tokens.toLocaleString()} tk</h2>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center g-8">
                        <h3>€{price.toLocaleString()} / month</h3>
                    </div>
                </div>
                <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                    {currentUser.plan.plan_name === name ? <p className="text-muted mb-0">Tap to manage</p> : <p className="text-muted mb-0">Tap to change</p>}
                </div>
            </button>
        </Tilt>

    )
        : <Loading />
}

export default SubPackage