// utils
import { lazy } from 'react';
import ServerModal from './ServerModal';
import RemoveServerModal from './RemoveServerModal';
import ServerOptionModal from './ServerOptionModal';
import GiveModal from './GiveModal';
import WarningModal from './WarningModal';

// components
const ActionsModal = lazy(() => import('@components/ActionsModal'));
const BetaModal = lazy(() => import('@components/BetaModal'));
const AppInfo = lazy(() => import('@widgets/AppInfo'));
const Account = lazy(() => import('@widgets/Account'));
const Notifications = lazy(() => import('@widgets/Notifications'));
const Chat = lazy(() => import('@widgets/Chat'));

const AppLayout = ({ children }) => {
    return (
        <div className="app">
            <div className="app_container">{children}</div>
            <ActionsModal />
            <BetaModal />
            <ServerModal />
            <GiveModal />
            <RemoveServerModal />
            <ServerOptionModal />
            <WarningModal />
            <AppInfo isSidebarWidget />
            <Account isSidebarWidget />
            <Notifications isSidebarWidget />
            <Chat isSidebarWidget />
        </div>
    )
}

export default AppLayout