import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@contexts/themeContext';
import store from '@app/store';
import { Provider } from 'react-redux';
import { AuthProvider } from '@contexts/AuthContext';

import '@fonts/icomoon/icomoon.woff';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <ThemeProvider>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </ThemeProvider>
        </Provider>
    </BrowserRouter>
);
