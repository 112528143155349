// styles
import Spring from '@components/Spring';


const NotificationsListItem = ({ index, title, description, author, relevant_link, type, priority, created_at }) => {
    return (
        <Spring index={index} type="list">
            <div className={`d-flex flex-column g-8 p-16 card bordered`}>
                <h4 className="d-flex align-items-center g-8 justify-content-between">
                    <div className="d-flex align-items-center">
                        {
                            priority === 'low' ?
                            <span className='indicator' style={{marginRight:'8px', background:'var(--info)'}} />
                            :
                            priority === 'medium' ?
                            <span className='indicator' style={{marginRight:'8px', background:'var(--warning)'}} />
                            :
                            <span className='indicator' style={{marginRight:'8px'}} />
                        }
                        {type}: {title}
                    </div>
                    <div>
                        {created_at}
                    </div>
                </h4>
                <p>{description}</p>
            </div>
        </Spring>
    )
}

export default NotificationsListItem