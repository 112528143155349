// styles
import styles from './style.module.scss';
import Tilt from 'react-parallax-tilt';

// hooks
import { useModal } from '@contexts/modalContext';
import { useAuth } from '@contexts/AuthContext';
import { useEffect, useState, useRef } from 'react';
import Loading from '@pages/Loading';
import { NavLink, Navigate } from 'react-router-dom';

import green from '@assets/offer-g.png';
import gray from '@assets/offer-d.png';
import purple from '@assets/offer-p.png';
import blue from '@assets/offer-b.png';
import Package from '@widgets/Package';
import { cancelSubscription, createSubscription, getUserInvoices, swapSubscription } from 'api/apiHandler';
import { LuDownloadCloud } from "react-icons/lu";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '@components/CheckoutForm';
import ScrollContainer from '@components/ScrollContainer';
import PackageButton from '@widgets/PackageButton';
import { current } from '@reduxjs/toolkit';

const stripePromise = loadStripe('pk_live_51OWO8SHYzbRPFUSxJ3Hn3UcwniyQgVbaLFD2sD1wPdw99vX4YHEXeHHuTe5bSukvtmhT4OktswnU1ofC5Xr52Tc000PRJpjvwa');

const Subscribe = ({ sub, select, features, subs }) => {
    const { handleOpen, setModalData, handleClose } = useModal();
    const { currentUser, softRefreshData } = useAuth();
    const [textStyle, setTextStyle] = useState(null);
    const [elementHeight, setElementHeight] = useState(0)
    const [elementWidth, setElementWidth] = useState(0)
    const [invoices, setInvoices] = useState([]);
    const [softRefreshed, setSoftRefreshed] = useState(false);
    const elementRef = useRef(null)

    const [stripeOptions, setStripeOptions] = useState(null);

    useEffect(() => {
        const updatedTextStyle = styles.GlowingText;

        setTextStyle(updatedTextStyle);
    }, [currentUser]);

    useEffect(() => {
        setElementHeight(elementRef.current.clientHeight);
        setElementWidth(elementRef.current.clientWidth);
    })

    // Stripe Checkout Session
    useEffect(() => {
        if (currentUser.plan_id == sub.id) {
            getUserInvoices().then(res => {
                console.log("User invoices: ", res);
                setInvoices(res.invoices);
            }
            ).catch(err => {
                console.log("Error getting user invoices: ", err);
            })
        }
        else if (currentUser.plan_id == 1) {
            if (sub.st_id) {
                createSubscription(sub.st_id).then(res => {
                    console.log("Subscription created: ", res);
                    setStripeOptions({
                        clientSecret: res.clientSecret, appearance: {
                            theme: 'night',
                            labels: 'floating',

                            variables: {
                                colorPrimary: '#8BCCB6',
                                colorBackground: '#232323',
                                colorText: '#fff',
                                colorDanger: '#f72b2b',
                                fontFamily: '"Manrope", sans-serif',
                                spacingUnit: '4px',
                                borderRadius: '5px',
                            },

                            rules: {
                                '.Tab': {
                                    border: 'none',
                                    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
                                },
                                '.Input': {
                                    border: 'none',
                                    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
                                },
                                '.Input--invalid': {
                                    border: 'none',
                                    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
                                },
                                '.Block': {
                                    border: 'none',
                                    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
                                },


                                // See all supported class names and selector syntax below
                            }
                        }
                    });
                }
                ).catch(err => {
                    console.log("Error creating subscription: ", err);
                })

            }
        }
    }, [sub, currentUser])

    const openCancelModal = () => {
        setModalData({
            warningModal: {
                title: 'Cancel subscription',
                description: 'Are you sure you want to cancel your subscription? Your subscription will be cancelled immediately, but you will keep your tokens.',
                buttons: [
                    {
                        text: 'Cancel',
                        action: () => handleClose(),
                    },
                    {
                        text: 'Cancel subscription',
                        action: () => submitCancelModal(),
                    },
                ],
            }
        });
        handleOpen('warning');
    }

    const submitCancelModal = () => {
        handleClose();
        cancelSubscription().then(res => {
            console.log("Subscription cancelled: ", res);
            softRefreshData();
        }
        ).catch(err => {
            console.log("Error cancelling subscription: ", err);
        })
    }

    return currentUser ? (
        <div
            ref={elementRef}
            className={`${styles.container} widget_body card`}
            style={{
                boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
            }}
        >

            <div className="d-flex flex-column g-8">
                <div className="d-flex flex-row justify-content-between align-items-center g-8">
                    <h2 style={{ fontSize: '1.5em' }}>{currentUser.plan_id == sub.id ? "Manage your subscription" : "Payment details"} </h2>
                    <button onClick={() => select(null)}>
                        <h4 className='d-flex flex-row align-items-center g-8' ><i className='icon icon-chevron-left'></i>Back to subscriptions</h4>
                    </button>
                </div>
            </div>

            {currentUser.plan_id == sub.id ?
                (
                    <div
                        className="d-flex flex-row scroll-y g-8"
                        style={{ width: '100%', height: '100%' }}>
                        <div className='d-flex flex-column align-items-center g-8'>
                            {// list subs
                                subs.map((sub, index) => (
                                    sub.id != currentUser.plan_id && sub.id != 1 &&
                                    <div key={index} className='d-flex flex-column align-items-center g-8' style={{ width: elementWidth - 350, height: 150 }}>
                                        <PackageButton sub={sub} features={features} select={select} />
                                    </div>
                                ))
                            }

                            <div
                                className={`widget_body card`}
                                style={{
                                    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
                                    width: elementWidth - 350,
                                    maxHeight: 250,
                                    backgroundColor: '#252525'
                                }}
                            >
                                <div className="d-flex flex-column" style={{}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center g-8">
                                        <h2 style={{}}>My invoices</h2>
                                    </div>
                                </div>

                                <div className="d-flex flex-column g-8 scroll-y ">

                                    {invoices.map((invoice, index) => (
                                        <button onClick={() => { window.open(invoice.invoice_pdf, '_blank'); }} key={index} className={`widget_body card`} style={{ height: 50, maxHeight: 50, minHeight: 50 }}>
                                            <div className='d-flex flex-row justify-content-between' style={{ width: '100%' }}>
                                                <h4>Invoice #{invoice.number} <span style={{ marginLeft: '8px', color: 'var(--label)' }}><small>{invoice.created_at}</small></span> <span style={{ marginLeft: '8px', color: 'var(--label)' }}><small>{(invoice.amount_paid / 100).toLocaleString()}€</small></span></h4>
                                                <span className='d-flex flex-row align-items-center'><LuDownloadCloud />&nbsp;&nbsp;<p>Download PDF</p></span>
                                            </div>
                                        </button>
                                    ))}
                                </div>

                            </div>
                            {currentUser.plan_id != 1 && <div className='d-flex flex-column align-items-center g-8 p-8' style={{ width: elementWidth - 350, paddingTop: 4 }}>
                                <button onClick={openCancelModal} style={{ backgroundColor: "transparent" }}>
                                    <div className={`d-flex flex-column justify-content-center align-items-center g-8`}>
                                        <h3 style={{ color: 'var(--red)' }}>Cancel subscription</h3>
                                    </div>
                                </button>
                                <p>You can also swap your subscription by selecting another package</p>
                            </div>}
                        </div>



                        <div
                            className={`${styles.container} widget_body card`}
                            style={{
                                borderRadius: '5px',
                                position: 'relative',  // This ensures inner content can be positioned relative to this card
                                flexShrink: 0,  // Prevent this div from shrinking below its natural size
                            }}>
                            {/* Right part */}
                            <Package sub={sub} features={features} />
                        </div>
                    </div>

                ) : (
                    <div
                        className="d-flex flex-row scroll-y g-8"
                        style={{ width: '100%', height: '100%' }}>

                        {stripeOptions || currentUser.plan_id > 1 ? (
                            <div className='d-flex flex-column align-items-center g-8' style={{ width: elementWidth - 350, height: '100%' }}>
                                {currentUser.plan_id == 1 ? (
                                    <Elements stripe={stripePromise} options={stripeOptions}>
                                        <CheckoutForm />
                                    </Elements>
                                ) : (
                                    <div className='d-flex column align-items-center' style={{ height: '100%' }}>
                                        <div
                                            className={`widget_body card justify-content-between`}
                                            style={{
                                                boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
                                                width: elementWidth - 350,
                                                maxWidth: 400,
                                                backgroundColor: '#252525'
                                            }}
                                        >
                                            <div className="d-flex flex-column" style={{}}>
                                                <div className="d-flex flex-row justify-content-between align-items-center g-8">
                                                    <h2 style={{}}>Swap subscription</h2>
                                                </div>
                                            </div>
                                            <p>You are currently subscribed to {currentUser.plan.plan_name}.</p>
                                            <p>Sorry, but at the moment we don't support swapping subscriptions.</p>
                                            <p>However, you can cancel your current subscription and then subscribe to another package, like {sub.name}.</p>
                                            <p>Don't worry, you will keep your tokens.</p>
                                            <div className="d-flex flex-column" style={{}}>
                                                <button onClick={openCancelModal} className="btn">
                                                    Cancel my subscription
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) :
                            <div className='d-flex flex-column align-items-center g-8' style={{ width: elementWidth - 350 }}>
                                <Loading />
                            </div>}

                        <div
                            className={`${styles.container} widget_body card`}
                            style={{
                                borderRadius: '5px',
                                position: 'relative',  // This ensures inner content can be positioned relative to this card
                                flexShrink: 0,  // Prevent this div from shrinking below its natural size
                            }}>
                            {/* Right part */}
                            <Package sub={sub} features={features} />
                        </div>
                    </div>
                )}



        </div>


    )
        : <Loading />
}


export default Subscribe