// styles
import styles from './style.module.scss';

// hooks
import { useAuth } from '@contexts/AuthContext';
import { useEffect } from 'react';
import { useState } from 'react';

import Loading from '@pages/Loading';
import ScrollContainer from '@components/ScrollContainer';
import ServerlistItem from '@widgets/ServerlistItem';


const Serverlist = () => {
    const { currentUser } = useAuth();
    const [servers, setServers] = useState(null);

    useEffect(() => {
        setServers(currentUser.servers);
    }, [currentUser.time, currentUser.servers]);

    return currentUser && servers ? (
        <div className={`${styles.container} widget_body card`}>
            <div className="d-flex flex-column g-8">
                <div className="d-flex flex-row g-8">
                    <h2>Your servers whitelist</h2><span>({currentUser.serversCount}/{currentUser.maxServersCount})</span>
                </div>
            </div>
            <ScrollContainer height={100} bg="widget-bg">

                <div className="track border-12" style={{ padding: '0 16px' }}>
                    <div className="d-flex flex-column g-8 pb-16 scroll-y">

                        <ServerlistItem variant={'newserver'}></ServerlistItem>
                        {servers.map((server, index) => (
                            <ServerlistItem
                                key={index}
                                variant={'item'}
                                online={server.online === 1 ? true : false}
                                servername={server.name ? server.name : 'Unknown'}
                                ip={server.ip_address ? server.ip_address : 'Unknown'}
                                gamemode={server.gametype ? server.gametype : 'Unknown'}
                                players={ server.players ? server.players.toString() : '0'}
                                maxplayers={server.max_players ? server.max_players.toString() : '0'}
                            />
                        ))}

                    </div>
                </div>
            </ScrollContainer>
        </div>
    )
        : <Loading />
}

export default Serverlist