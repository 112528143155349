// components
import ModalContainer from '@components/ModalContainer';

// hooks
import { useModal } from '@contexts/modalContext';
import { useEffect, useState } from 'react';
import CenteredWrapper from './CenteredWrapper';
import { Dna } from 'react-loader-spinner';
import { useAuth } from '@contexts/AuthContext';
import { removeWhitelist } from 'api/apiHandler';

const RemoveServerModal = () => {
    const { open, modal, handleClose, modalData } = useModal();
    const [subError, setSubError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { refreshData } = useAuth();

    useEffect(() => {
        if (!open) {
            setSubError('');
        }
    }, [open])

    const handleSubmit = () => {
        setIsLoading(true);
        removeWhitelist(modalData.ip).then(response => {
            setIsLoading(false);
            if (response.error) {
                setSubError(response.error);
            } else {
                refreshData();
                handleClose();
            }
        }).catch(error => {
            setSubError(error)
        });
    }

    return (

        <ModalContainer open={open && modal === 'removeServer'} handleClose={handleClose}>
            <div className="widget_body modal card d-flex flex-column" style={{ gap: 40 }}>
                <div className="d-flex flex-column g-8">
                    <div>
                        <h2 className="text-large">Do you want to remove this server from your whitelist ?</h2>
                    </div>

                    {isLoading ? <CenteredWrapper height={'100%'}>
                        <Dna
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                        />
                    </CenteredWrapper> :
                        <div className="d-flex flex-column g-8" style={{ marginTop: '10px', marginBottom: '-10px', textAlign:'center' }}>
                                <h2>{modalData.ip}</h2>
                        </div>}
                            <p style={{ color: 'var(--red)', paddingLeft: '8px' }}>{subError}</p>
                </div>
                <div className="d-grid col-2 g-16">
                    <button className="btn btn--invert" onClick={handleClose}>No</button>
                    <button className="btn" onClick={handleSubmit}>
                        Yes
                    </button>
                </div>
            </div>
        </ModalContainer>
    )
}

export default RemoveServerModal