const LINKS = [
    {path: '/', mobile: 'Home', desktop: 'Home', icon: 'dashboard', icon_filled: 'dashboard', isBeta: false},
    {path: '/logs', mobile: 'Logs', desktop: 'Chat Logs', icon: 'trade',icon_filled: 'trade' , isBeta: true},
    {path: '/subscriptions', desktop: 'Subscription', icon: 'star-o', icon_filled: 'star-o', isBeta: false},
    {path: '/wallet', desktop: 'Wallet', icon: 'wallet', icon_filled: 'wallet', isBeta: true},
    {path: '/api', desktop: 'API', icon: 'collections', icon_filled: 'collections', isBeta: true},
    {path: '/moderation', mobile: 'Moderation', desktop: 'Moderation', icon: 'verification', icon_filled: 'verification', isBeta: true},
    {path: '/support', mobile: 'Support', desktop: 'Support & Help', icon: 'headset', icon_filled: 'headset', isBeta: true},
]

export default LINKS