// components
import ModalContainer from '@components/ModalContainer';

// hooks
import { useModal } from '@contexts/modalContext';
import { useEffect, useState } from 'react';
import CenteredWrapper from './CenteredWrapper';
import { Dna } from 'react-loader-spinner';
import LabelField from './LabelField';
import { PatternFormat } from 'react-number-format';
import { addWhitelist } from 'api/apiHandler';
import { useAuth } from '@contexts/AuthContext';

const ServerModal = () => {
    const { open, modal, handleClose } = useModal();
    const [inputValue, setInputValue] = useState('');
    const [subError, setSubError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { refreshData } = useAuth();

    useEffect(() => {
        if (!open) {
            setSubError('');
            setInputValue('');
        }
    }, [open])

    const handleSubmit = (ip) => {
        setIsLoading(true);
        addWhitelist(ip).then(response => {
            setIsLoading(false);
            if (response.error) {
                setSubError(response.error);
            } else {
                setInputValue('');
                refreshData();
                handleClose();
            }
        }).catch(error => {
            setSubError(error)
        });
    }

    return (

        <ModalContainer open={open && modal === 'server'} handleClose={handleClose}>
            <div className="widget_body modal card d-flex flex-column" style={{ gap: 40 }}>
                <div className="d-flex flex-column g-8">
                    <div>
                        <h2 className="text-large">Server whitelist</h2>
                    </div>

                    {isLoading ? <CenteredWrapper height={'100%'}>
                        <Dna
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                        />
                    </CenteredWrapper> :
                        <div className="d-flex flex-column g-8" style={{ marginTop: '10px', marginBottom: '-10px' }}>
                            <LabelField id="server-ip" label="Server IP"
                                value={inputValue}
                                onChange={(event) => setInputValue(event.target.value)} />
                            <p style={{ color: 'var(--red)', paddingLeft: '8px' }}>{subError}</p>
                        </div>}
                </div>
                <button className="btn" onClick={() => handleSubmit(inputValue)}>
                    Add Server
                </button>
            </div>
        </ModalContainer>
    )
}

export default ServerModal