// styles
import styles from './style.module.scss';
import Tilt from 'react-parallax-tilt';

// hooks
import { useModal } from '@contexts/modalContext';
import { useAuth } from '@contexts/AuthContext';
import { useEffect, useState, useRef } from 'react';
import Loading from '@pages/Loading';
import { NavLink, Navigate } from 'react-router-dom';

import green from '@assets/offer-g.png';
import gray from '@assets/offer-d.png';
import purple from '@assets/offer-p.png';
import blue from '@assets/offer-b.png';
import { element } from 'prop-types';


const PackageButton = ({ sub, features, select }) => {
    const { handleOpen, setModalData } = useModal();
    const { currentUser } = useAuth();

    // =========================================================================
    // Elements sizes
    // =========================================================================

    // Main element
    const [elementHeight, setElementHeight] = useState(0)
    const [elementWidth, setElementWidth] = useState(0)
    const elementRef = useRef(null)

    // Select button
    const [selectHeight, setSelectHeight] = useState(0)
    const [selectWidth, setSelectWidth] = useState(0)
    const selectRef = useRef(null)

    // Package card
    const [packageHeight, setPackageHeight] = useState(0)
    const [packageWidth, setPackageWidth] = useState(0)
    const packageRef = useRef(null)


    useEffect(() => {
        setElementHeight(elementRef.current.clientHeight);
        setElementWidth(elementRef.current.clientWidth);

        if (selectRef.current) {
            setSelectHeight(selectRef.current.clientHeight);
            setSelectWidth(selectRef.current.clientWidth);
        }

        if (packageRef.current) {
            setPackageHeight(packageRef.current.clientHeight);
            setPackageWidth(packageRef.current.clientWidth);
        }
    })

    return (
        <Tilt scale={0.98} tiltEnable={false} height={'100%'} style={{ width: '100%', height: '100%' }}>
            <button
                ref={elementRef}
                onClick={() => select(sub)}
                className={`${styles.container} widget_body card`}
                style={{
                    backgroundColor: '#252525',
                    borderRadius: '10px',
                    width: '100%',  // Fixed width for inner card
                    position: 'absolute',
                    right: '0',   // This sticks the inner card to the right side of the parent card
                    overflow: 'hidden',     // This will hide any overflowing child content
                    top: '0'      // This positions the inner card at the top of the parent card; adjust as needed
                }}>
                {/* Inner right card content */}

                <img src={sub.id == 1 ? gray : sub.id == 2 ? blue : sub.id == 3 ? green : purple} alt=""
                    style={{
                        transform: 'scale(1.3)',   // Make the image larger than its parent
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        height: '200px',
                    }} />


                {
                    elementHeight < 400 ?
                        (
                            <h1 style={{ position: 'absolute', right: '32px' }}>{sub.name} </h1>
                        )
                        :
                        (
                            <h2 style={{ position: 'absolute', right: '32px' }}>{sub.name} </h2>
                        )
                }
                {/* <small style={{ position: 'absolute', right: '32px', top: '50px', opacity: '50%' }}>({elementHeight} / {elementWidth})</small> */}


                {elementHeight > 400 && (
                    <div ref={packageRef} style={currentUser.plan_id == sub.id ?
                        { backgroundColor: "#61aa91", width: "65%", height: '60px', position: 'absolute', borderRadius: '5px', top: '200px', left: (elementWidth / 2 - packageWidth / 2), boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', }
                        : { backgroundColor: "#202020", width: "65%", height: '60px', position: 'absolute', borderRadius: '5px', top: '200px', left: (elementWidth / 2 - packageWidth / 2), boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', }
                    }>
                        <div className={`${styles.container} d-flex flex-row justify-content-between align-items-center g-8 p-16`}>
                            <h3>{sub.name} plan</h3>
                            <h4>€{sub.price} / month</h4>
                        </div>
                    </div>
                )}

                {
                    elementHeight > 600 ? (
                        <div
                            className="d-flex flex-column justify-content-between align-items-center g-8"
                            style={{ width: '100%', height: '100%' }}>

                            <div style={{ width: '100%', height: '90%' }}></div>
                            <div style={{ width: '100%', height: '100%', pointerEvents: 'none', paddingLeft: '40px' }}
                                className='d-flex flex-column g-8'
                            >
                                {features.INCLUDED.map((feature, index) => (
                                    <span key={index} className='d-flex flex-row text-align-left' style={{ textAlign: 'left', textAlignLast: 'left' }}><h3 style={{ fontWeight: 'lighter', fontSize: '1.3em' }}>{feature}</h3></span>
                                ))}
                                {features.MISSING.map((feature, index) => (
                                    <span key={index} className='d-flex flex-row  align-items-center text-align-left g-8' style={{ textAlign: 'left', textAlignLast: 'left' }}><i className='icon icon-xmark'></i><h3 style={{ fontWeight: 'lighter', color: 'var(--text-light)' }}>{feature}</h3></span>
                                ))}
                            </div>
                        </div>
                    )
                        :
                        elementHeight > 400 ? (
                            <div
                                className="d-flex flex-column justify-content-between align-items-center g-8"
                                style={{ width: '100%', height: '100%' }}>

                                <div style={{ width: '100%', height: '100%' }}></div>
                                <div style={{ width: '100%', height: '60%', pointerEvents: 'none', paddingLeft: '40px' }}
                                    className='d-flex flex-column g-8'
                                >
                                </div>
                            </div>
                        )
                            :
                            (

                                <h2 style={{ position: 'absolute', left: '32px', bottom: '32px' }}>Select {sub.name} </h2>
                            )

                }

                {elementHeight > 400 && (
                    <button ref={selectRef} style={{ backgroundColor: elementHeight < 400 ? "#282828" : "#282828", width: "70%", height: '60px', position: 'absolute', borderRadius: '5px', bottom: '24px', left: (elementWidth / 2 - selectWidth / 2), zIndex: 2, }}>
                        <div className={`${styles.container} d-flex flex-row justify-content-center align-items-center g-8 p-16`}>
                            {sub.id == currentUser.plan_id ?
                                (
                                    <h3>Manage</h3>
                                ) :
                                (
                                    <h3>Select {sub.name}</h3>
                                )}
                        </div>
                    </button>
                )}
            </button>

        </Tilt>

    )
}


export default PackageButton