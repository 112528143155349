// =============================================================================
// Imports
// =============================================================================
// GA
import ReactGA from 'react-ga4';

// utils
import { lazy, Suspense } from 'react';

// styles
import ThemeStyles from '@styles/theme';
import './style.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-grid-layout/css/styles.css';

// contexts
import { SidebarProvider } from '@contexts/sidebarContext';
import { ThemeProvider } from 'styled-components';
import { ModalProvider } from '@contexts/modalContext';

// hooks
import { useThemeProvider } from '@contexts/themeContext';
import { useEffect, useRef } from 'react';
import { useWindowSize } from 'react-use';

// components
import { useAuth } from '@contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Sidebar from '@components/Sidebar';
import BottomNav from '@components/BottomNav';
import { PublicRoute, PrivateRoute, AdminRoute } from '@pages/Conditions/ProtectedRoutes';
import Loading from '@pages/Loading';
import NotificationsPage from '@pages/NotificationsPage';
import Users from '@pages/Users';
import Subscriptions from '@pages/Subscriptions';
import FinishCheckout from '@pages/FinishCheckout';

// =============================================================================
// Pages
// =============================================================================
const Dashboard = lazy(() => import('@pages/Dashboard'));
const Home = lazy(() => import('@pages/Home'));
const SignIn = lazy(() => import('@pages/SignIn'));

// =============================================================================
// Other pages
// =============================================================================

// =============================================================================
// Application
// =============================================================================
const App = () => {
    const appRef = useRef(null);
    const { theme } = useThemeProvider();
    const { width } = useWindowSize();
    const { currentUser, isLoading } = useAuth();

    useEffect(() => {
        if (currentUser) {
            console.log("User logged in:", currentUser);
            // Fetch user-specific data or perform other side-effects here
        } else {
            console.log("User not logged in.");
        }
    }, [currentUser]);

    useEffect(() => {
        appRef.current && appRef.current.scrollTo(0, 0);
    }, []);

    const gaKey = process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS;
    gaKey && ReactGA.initialize(gaKey);

    return (

        <SidebarProvider>
            <ThemeProvider theme={{ theme: theme }}>
                <ThemeStyles />
                <ToastContainer theme="colored" autoClose={2000} toastStyle={{ borderRadius: 4 }} />
                {
                    isLoading ? <Loading />
                        :
                        <ModalProvider>
                            <div ref={appRef}>
                                {width < 768 ? <BottomNav /> : <Sidebar />}
                                {
                                    <Suspense fallback={<Loading />}>
                                        <Routes>
                                            {
                                                // =========================================
                                                // Private routes
                                                // =========================================
                                            }
                                            <Route path="/" element={
                                                <PrivateRoute>
                                                    <Home />
                                                </PrivateRoute>
                                            } />
                                            <Route path="/subscriptions" element={
                                                <PrivateRoute>
                                                    <Subscriptions />
                                                </PrivateRoute>
                                            } />
                                            <Route path="/finishCheckout" element={
                                                <PrivateRoute>
                                                    <FinishCheckout />
                                                </PrivateRoute>
                                            } />
                                            <Route path="/dash" element={
                                                <AdminRoute>
                                                    <Dashboard />
                                                </AdminRoute>
                                            } />
                                            <Route path="/notifications" element={
                                                <AdminRoute>
                                                    <NotificationsPage />
                                                </AdminRoute>
                                            } />
                                            <Route path="/users" element={
                                                <AdminRoute>
                                                    <Users />
                                                </AdminRoute>
                                            } />

                                            {
                                                // =========================================
                                                // Public routes
                                                // =========================================
                                            }
                                            <Route path="/sign-in" element={
                                                <PublicRoute>
                                                    <SignIn />
                                                </PublicRoute>
                                            } />
                                            <Route path="*" element={<Navigate to="/" />} />
                                        </Routes>
                                    </Suspense>

                                }
                            </div>
                        </ModalProvider>}
            </ThemeProvider>
        </SidebarProvider>
    );
}

export default App;
