// styling
import styles from './style.module.scss';


// constants
import LINKS from '@constants/links';
import MOBILELINKS from '@constants/mobilelinks';

// utils
import { memo } from 'react';

// hooks
import { useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useThemeProvider } from '@contexts/themeContext';
import { useModal } from '@contexts/modalContext';
import useAuthRoute from '@hooks/useAuthRoute';
import { useWindowSize } from 'react-use';

const BottomNav = () => {
    const ref = useRef(null);
    const location = useLocation();
    const { theme } = useThemeProvider();
    const isActive = (path) => location.pathname === path;
    const isAuthRoute = useAuthRoute();
    const { handleOpen } = useModal();
    const { width } = useWindowSize();

    const isMobile = width <= 768;

    useEffect(() => {
        const height = ref.current.offsetHeight;
        window.document.documentElement.style.setProperty('--bottom-nav-height', `${height}px`);


        return () => {
            window.document.documentElement.style.removeProperty('--bottom-nav-height');
        }
    }, []);

    return (
        <>
            {
                !isAuthRoute ? (
                    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : ''}`} ref={ref}>
                        {
                            isMobile ? 
                            MOBILELINKS.map(link => {
                                const { icon, path, desktop, mobile } = link;
                                const isSm = icon === 'collections';

                                return (
                                    // <NavLink className={`nav-link ${isActive(path) ? 'active' : ''}`} to={path} key={path}>
                                    //     <span className="wrapper">
                                    //         <i className={`icon icon-${icon} ${isSm ? 'sm' : ''}`} />
                                    //         <i className={`icon icon-fill ${isActive(path) ? 'active' : ''} icon-${icon}${icon !== 'trade' ? '-active' : ''} ${isSm ? 'sm' : ''}`} />
                                    //     </span>
                                    //     {mobile ? mobile : desktop}
                                    // </NavLink>
                                    <NavLink to={path} className={`nav-link ${isActive(path) ? 'active' : ''}`} key={path}>
                                        <span className="wrapper">
                                            <i className={`icon icon-${icon} ${isSm ? 'sm' : ''}`} />
                                            <i className={`icon icon-fill ${isActive(path) ? 'active' : ''} icon-${icon}${icon !== 'trade' ? '-active' : ''} ${isSm ? 'sm' : ''}`} />
                                        </span>
                                        {mobile ? mobile : desktop}
                                    </NavLink>
                                )
                            })
                            :
                            LINKS.map(link => {
                                const { icon, path, desktop, mobile } = link;
                                const isSm = icon === 'collections';

                                return (
                                    // <NavLink className={`nav-link ${isActive(path) ? 'active' : ''}`} to={path} key={path}>
                                    //     <span className="wrapper">
                                    //         <i className={`icon icon-${icon} ${isSm ? 'sm' : ''}`} />
                                    //         <i className={`icon icon-fill ${isActive(path) ? 'active' : ''} icon-${icon}${icon !== 'trade' ? '-active' : ''} ${isSm ? 'sm' : ''}`} />
                                    //     </span>
                                    //     {mobile ? mobile : desktop}
                                    // </NavLink>
                                    <NavLink to={path} className={`nav-link ${isActive(path) ? 'active' : ''}`} key={path} onClick={() => handleOpen('beta')}>
                                        <span className="wrapper">
                                            <i className={`icon icon-${icon} ${isSm ? 'sm' : ''}`} />
                                            <i className={`icon icon-fill ${isActive(path) ? 'active' : ''} icon-${icon}${icon !== 'trade' ? '-active' : ''} ${isSm ? 'sm' : ''}`} />
                                        </span>
                                        {mobile ? mobile : desktop}
                                    </NavLink>
                                )
                            })
                        }
                    </div>
                ) : null
            }
        </>
    )
}

export default memo(BottomNav);