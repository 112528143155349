import React, { createContext, useContext, useState, useEffect } from 'react';
import { verifyJWT } from 'api/apiHandler';

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [baseAccess, setBaseAccess] = useState(false);

  const logOut = () => {
    setIsLoading(true);
    localStorage.removeItem('jwt');
    setCurrentUser(null);
    setIsLoading(false);
  }

  const softRefreshData = () => {
    const token = localStorage.getItem('jwt');
    if (token) {
      verifyJWT(token).then(response => {
        if (response.isValid) {
          console.log("soft refreshed: ", response.user.name);
          setCurrentUser(response.user);
        } else {
          console.log('soft refresh failed');
        }
      }).catch(error => {
        console.log('soft refresh failed', error);
      });
    }
    else {
      console.log('no token, soft refresh failed');
    }
  }


  const refreshData = () => {
    const token = localStorage.getItem('jwt');  // Assuming 'jwt' is the key you used to store the token in localStorage
    if (token) {
      setIsLoading(true);
      verifyJWT(token).then(response => {
        if (response.isValid) { // Depending on what your backend returns, you can adjust this
          console.log("Hello, ", response.user.name);
          setCurrentUser(response.user); // Set the currentUser to token or to the user data returned by your backend
          setIsLoading(false);
        } else {
          localStorage.removeItem('jwt'); // The token is not valid, so remove it
          setIsLoading(false);
        }
      }).catch(error => {
        console.error("Error verifying JWT:", error);
        setIsLoading(false);
      });
    }
    else {
      setIsLoading(false);
    }
  }

  // Check and validate the JWT when the component initializes
  useEffect(() => {
    const token = localStorage.getItem('jwt'); 
    if (token) {
      setIsLoading(true);
      verifyJWT(token).then(response => {
        if (response.isValid) { 
          console.log("Hello, ", response.user.name);
          setCurrentUser(response.user); // Set the currentUser to token or to the user data returned by your backend
          setIsLoading(false);
        } else {
          localStorage.removeItem('jwt'); // The token is not valid, so remove it
          setIsLoading(false);
        }
      }).catch(error => {
        console.error("Error verifying JWT:", error);
        setIsLoading(false);
      });
    }
    else {
      setIsLoading(false);
    }
  }, []);

  const value = {
    currentUser,
    setCurrentUser,
    isLoading,
    setIsLoading,
    logOut,
    refreshData,
    baseAccess,
    setBaseAccess,
    notifications,
    setNotifications,
    users,
    setUsers,
    softRefreshData,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
