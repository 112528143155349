// components
import AppLayout from '@components/AppLayout';
import PageHeader from '@components/PageHeader';
import AppGrid from '@components/AppGrid';
import NotificationsList from '@widgets/NotificationsList';


const NotificationsPage = () => {

    const widgets = {
        notifications: <NotificationsList/>,
    }

    return (
        <AppLayout>
            <PageHeader title="Notifications" variant="dashboard" />
            <AppGrid id="notifications" widgets={widgets} />
        </AppLayout>
    )
}

export default NotificationsPage