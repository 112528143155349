// components
import ModalContainer from '@components/ModalContainer';

// hooks
import { useModal } from '@contexts/modalContext';
import { useEffect, useState } from 'react';
import CenteredWrapper from './CenteredWrapper';
import { Dna } from 'react-loader-spinner';
import LabelField from './LabelField';
import { manualTransaction } from 'api/apiHandler';
import { useAuth } from '@contexts/AuthContext';

const GiveModal = () => {
    const { open, modal, handleClose, modalData } = useModal();
    const [inputValue, setInputValue] = useState('');
    const [subError, setSubError] = useState('');
    const [subMessage, setSubMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const { currentUser } = useAuth();

    useEffect(() => {
        if (!open) {
            setSubError('');
            setInputValue('');
            setSubMessage('');
            setIsSent(false);
            
        }
    }, [open])

    const handleSubmit = (am) => {
        setIsLoading(true);
        if (!parseInt(am)) {
            setSubError("Cannot parse " + am);
        } else {
            manualTransaction(parseInt(am), modalData.id).then(response => {
                setIsLoading(false);
                if (response.error) {
                    setSubError("Cannot process request");
                } else {
                    setInputValue('');
                    setSubMessage('We sent you a confirmation to the email: '+currentUser.email);
                    setIsSent(true);
                }
            }).catch(error => {
                setSubError("Cannot process request")
            });
        }
    }

    return (

        <ModalContainer open={open && modal === 'give'} handleClose={handleClose}>
            <div className="widget_body modal card d-flex flex-column" style={{ gap: 40 }}>
                <div className="d-flex flex-column g-8">
                    <div>
                        {modalData.name && <h2 className="text-large">Transfer tokens to {modalData.name}</h2>}
                    </div>

                    {isLoading ? <CenteredWrapper height={'100%'}>
                        <Dna
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                        />
                    </CenteredWrapper> :
                        !isSent ?
                            <div className="d-flex flex-column g-8" style={{ marginTop: '10px', marginBottom: '-10px' }}>
                                <LabelField id="token-amount" label="Token Amount" type="number" alue={inputValue}
                                    onChange={(event) => setInputValue(event.target.value)} />
                                <p style={{ color: 'var(--red)', paddingLeft: '8px' }}>{subError}</p>
                            </div>
                            :
                            <div className="d-flex flex-column g-8" style={{ marginTop: '10px', marginBottom: '-10px' }}>
                                <p style={{paddingLeft: '8px' }}>{subMessage}</p>
                            </div>
                    }
                </div>
                {isSent
                ?
                <button className="btn" onClick={() => handleClose()}>
                    Ok
                </button>
                :
                <button className="btn" onClick={() => handleSubmit(inputValue)}>
                    Confirm transfer
                </button>
                }
            </div>
        </ModalContainer>
    )
}

export default GiveModal