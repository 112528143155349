// components
import AppLayout from '@components/AppLayout';
import PageHeader from '@components/PageHeader';
import AppGrid from '@components/AppGrid';
import Membership from '@widgets/Membership';
import Serverlist from '@widgets/Serverlist';
import StartOffer from '@widgets/StartOffer';
import SubPackage from '@widgets/SubPackage';
import { useEffect, useState } from 'react';
import { useAuth } from '@contexts/AuthContext';
import Subscribe from '@widgets/Subscribe';
import Package from '@widgets/Package';
import PackageButton from '@widgets/PackageButton';
import FinishCheck from '@widgets/FinishCheck';
import { verifyIntent } from 'api/apiHandler';
import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti'

const FinishCheckout = () => {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const { currentUser, softRefreshData } = useAuth();
    const params = new URLSearchParams(window.location.search);
    const paymentIntent = params.get('payment_intent');
    const [intentVerified, setIntentVerified] = useState(false);
    const [intentAlreadyVerified, setIntentAlreadyVerified] = useState(false);
    const { width, height } = useWindowSize()
    const [runConfetti, setRunConfetti] = useState(false);
    const [recycleConfetti, setRecycleConfetti] = useState(true);
    const [paymentError, setPaymentError] = useState(false);
    const [planId, setPlanId] = useState(null);

    useEffect(() => {
        if (paymentIntent) {
            console.log("Payment intent: ", paymentIntent);
            verifyIntent(paymentIntent).then(res => {
                if (res.status === 'succeeded') {
                    softRefreshData();
                    console.log("Intent verified: ", res);
                    setPlanId(res.plan);
                    setIntentVerified(true);
                }
                else {
                    if (res.status === 'verified') {
                        console.log("Intent already verified");
                        setPlanId(res.plan);
                        setIntentAlreadyVerified(true);
                    }
                    else {
                        console.log("Intent failed: ", res);
                        setIntentVerified(false);
                        setPaymentError(true)
                    }
                }
            }).catch(err => {
                console.log("Intent error: ", err);
                setIntentVerified(false);
                setPaymentError(true)
            })
        }
        else {
            window.location.href = '/subscriptions';
        }
    }, [paymentIntent]);


    // TODO: update FORMATTED

    const FEATURES = [
        // Basic
        {
            INCLUDED: [
                "Optimize players inputs",
                "Access to classic AI model",
            ], MISSING: [

            ], FORMATTED: [
                "Optimizing players inputs,",
                "Accessing 1 fine tuned AI model,",
                "And more...",
            ]
        },
        // Starter
        {
            INCLUDED: [
                "Get 1,500,000 tokens per month",
                "Optimize players inputs",
                "Access to 1 fine tuned AI model",
            ], MISSING: [

            ], FORMATTED: [
                "Enjoy your 1,500,000 tokens,",
                "Optimizing players inputs,",
                "Accessing 1 fine tuned AI model,",
                "And more...",
            ]
        },
        // Elite
        {
            INCLUDED: [
                "Get 3,000,000 tokens per month",
                "Optimize players inputs",
                "Access to 3 fine tuned AI model",
                "Custom context enabled",
            ], MISSING: [

            ], FORMATTED: [
                "Enjoy your 3,000,000 tokens,",
                "Optimizing players inputs,",
                "Accessing 3 fine tuned AI models,",
                "And more...",
            ]
        },
        // Premium
        {
            INCLUDED: [
                "Get 6,000,000 tokens per month",
                "Access to 5+ fine tuned AI model",
                "Access to early AI models before release",
                "Custom context enabled",
                "Remove forced context",
                "Token burst cover up to 100,000 tokens",
                "Customize in-game interface with css"
            ], MISSING: [
            ], FORMATTED: [
                "Enjoy your 6,000,000 tokens,",
                "Accessing 5+ fine tuned AI models,",
                "Accessing early AI models before release,",
                "Customizing context,",
                "Removing forced context,",
                "Token burst cover up to 100,000 tokens,",
                "Customizing in-game interface with css,",
                "And more...",
            ]
        },
    ];

    const SUBS = [
        { id: 1, name: 'Basic', tokens: 0, price: 0.00, st_id: null },
        { id: 2, name: 'Starter', tokens: 1500000, price: 3.99, st_id: 'price_1OdtzMHYzbRPFUSxGpAbrMJu' },
        { id: 3, name: 'Elite', tokens: 3000000, price: 6.99, st_id: 'price_1OdtzMHYzbRPFUSxApaMtioO' },
        { id: 4, name: 'Premium', tokens: 6000000, price: 12.99, st_id: 'price_1OdtzMHYzbRPFUSxWajnIwan' },
    ];

    const widget = {
        sub: <FinishCheck sub={SUBS[planId-1]} features={FEATURES[planId-1]} verified={intentVerified} setRunConfetti={setRunConfetti} setRecycleConfetti={setRecycleConfetti} alreadyVerified={intentAlreadyVerified} paymentError={paymentError} planId={planId}/>
    }

    return (
        <AppLayout>

            <Confetti
                width={width}
                height={height}
                run={runConfetti}
                recycle={recycleConfetti}
            />
            <PageHeader title="Subscription" variant="dashboard" />
            <AppGrid id="finishCheckout" widgets={widget} />
        </AppLayout>
    )
}

export default FinishCheckout