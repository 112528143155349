// components
import ModalContainer from '@components/ModalContainer';

// hooks
import { useModal } from '@contexts/modalContext';
import { useEffect, useState } from 'react';
import CenteredWrapper from './CenteredWrapper';
import { Dna } from 'react-loader-spinner';
import { useAuth } from '@contexts/AuthContext';

const WarningModal = () => {
    const { open, modal, handleClose, modalData } = useModal();
    const [subError, setSubError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!open) {
            setSubError('');
        }
    }, [open])

    const handleSubmit = () => {
        setIsLoading(true);

    }

    return (

        <ModalContainer open={open && modal === 'warning'} handleClose={handleClose}>
            <div className="widget_body modal card d-flex flex-column" style={{ gap: 40 }}>
                {modalData.warningModal && (
                    <>
                        <div className="d-flex flex-column g-8">
                            <div>
                                <h2 className="text-large">{modalData.warningModal.title}</h2>
                            </div>
                            <div className="d-flex flex-column g-8" style={{}}>
                                <p>{modalData.warningModal.description}</p>
                            </div>
                        </div>
                        <div className="d-grid col-2 g-16">
                            {
                                modalData.warningModal.buttons.map((button, index) => (
                                    <button key={index} className={`btn ${index % 2 == 0 && 'btn--invert'}`} onClick={button.action}>
                                        {button.text}
                                    </button>
                                ))
                            }
                        </div>
                    </>
                )}
            </div>
        </ModalContainer>
    )
}

export default WarningModal