// components
import AppLayout from '@components/AppLayout';
import PageHeader from '@components/PageHeader';
import AppGrid from '@components/AppGrid';
import Membership from '@widgets/Membership';
import Serverlist from '@widgets/Serverlist';
import StartOffer from '@widgets/StartOffer';
import SubPackage from '@widgets/SubPackage';
import { useState } from 'react';
import { useAuth } from '@contexts/AuthContext';
import Subscribe from '@widgets/Subscribe';
import Package from '@widgets/Package';
import PackageButton from '@widgets/PackageButton';

const Subscriptions = () => {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const { currentUser } = useAuth();

    const FEATURES = [
        // Basic
        {
            INCLUDED: [
                "Optimize players inputs",
                "Access to classic AI model",
            ], MISSING: [
                "Get tokens every month",
                "Access to fine tuned AI model",
                "Custom context enabled",
                "Remove forced context",
                "Token burst cover",
                "Customize in-game interface with css"
            ]
        },
        // Starter
        {
            INCLUDED: [
                "Get 1,500,000 tokens per month",
                "Optimize players inputs",
                "Access to 1 fine tuned AI model",
            ], MISSING: [
                "Plus AI models",
                "Custom context enabled",
                "Remove forced context",
                "Token burst cover",
                "Customize in-game interface with css"
            ]
        },
        // Elite
        {
            INCLUDED: [
                "Get 3,000,000 tokens per month",
                "Optimize players inputs",
                "Access to 3 fine tuned AI model",
                "Custom context enabled",
            ], MISSING: [
                "Premium AI models",
                "Remove forced context",
                "Token burst cover",
                "Customize in-game interface with css"
            ]
        },
        // Premium
        {
            INCLUDED: [
                "Get 6,000,000 tokens per month",
                "Access to 5+ fine tuned AI model",
                "Access to early AI models before release",
                "Custom context enabled",
                "Remove forced context",
                "Token burst cover up to 100,000 tokens",
                "Customize in-game interface with css"
            ], MISSING: [
            ]
        },
    ];

    const SUBS = [
        {id:1, name:'Basic', tokens:0, price:0.00, st_id:null},
        {id:2, name:'Starter', tokens:1500000, price:3.99, st_id:'price_1OdtzMHYzbRPFUSxGpAbrMJu'},
        {id:3, name:'Elite', tokens:3000000, price:6.99, st_id:'price_1OdtzMHYzbRPFUSxApaMtioO'},
        {id:4, name:'Premium', tokens:6000000, price:12.99, st_id:'price_1OdtzMHYzbRPFUSxWajnIwan'}
    ]



    const packages = {
        //pack_1: <SubPackage id={1} name={'Basic'} tokens={0} price={0.00} select={setSelectedPackage} />,
        pack_1: <PackageButton sub={SUBS[0]} features={FEATURES[0]} select={setSelectedPackage} />,
        pack_2: <PackageButton sub={SUBS[1]} features={FEATURES[1]} select={setSelectedPackage} />,
        pack_3: <PackageButton sub={SUBS[2]} features={FEATURES[2]} select={setSelectedPackage} />,
        pack_4: <PackageButton sub={SUBS[3]} features={FEATURES[3]} select={setSelectedPackage} />,
    }

    const subscriptions = {
        sub: <Subscribe select={setSelectedPackage} sub={selectedPackage} features={selectedPackage ? FEATURES[selectedPackage.id - 1] : null} subs={SUBS} />
    }

    return (
        <AppLayout>
            {selectedPackage == null ? <PageHeader title="Subscription" variant="dashboard" /> : (selectedPackage.name == currentUser.plan.plan_name ? <PageHeader title="Manage Subscription" variant="dashboard" /> : <PageHeader title="Confirm Subscription" variant="dashboard" />)}
            {selectedPackage == null && <AppGrid id="subscriptions" widgets={packages} />}
            {selectedPackage && <AppGrid id="subscriptions" widgets={subscriptions} />}
        </AppLayout>
    )
}

export default Subscriptions