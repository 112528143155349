import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.horizonsweb.services/third/d9cb347a-bb44-486d-a024-62cf08c6ce74/',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to get JWT with an exchange code
export const getJWTWithExchangeCode = async (exchangeCode) => {
  try {
    const response = await api.post('exchangeCode', {
      exchange_code: exchangeCode,
    });
    return response.data.jwt;
  } catch (error) {
    throw new Error('Failed to retrieve JWT');
  }
};

// Function to verify the JWT token
export const verifyJWT = async (token) => {
  try {
    const response = await api.post('verifyJWT', { jwt: token });
    return response.data; // This will contain user details or a validation status
  } catch (error) {
    throw new Error('Failed to verify JWT');
  }
};

// Function to send a whitelist a server with JWT
export const getNotifications = async (ip) => {
  try {
    const response = await api.post('getNotifications', {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
};

// Function to send a whitelist a server with JWT
export const getUsers = async (ip) => {
  try {
    const response = await api.post('getUsers', {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
};

// Function to create a manual transaction (give)
export const manualTransaction = async (amount, to) => {
  try {
    const response = await api.post('createManualTransaction', { amount: amount, from: 0, to: to }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
};

// Function to send a whitelist a server with JWT
export const addWhitelist = async (ip) => {
  try {
    const response = await api.post('whitelist', { ip: ip }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
};

// Function to remove a server from the user's whitelist
export const removeWhitelist = async (ip) => {
  try {
    const response = await api.post('removeWhitelist', { ip: ip }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
};

export const getServerConfig = async (ip) => {
  try {
    const response = await api.post('getServerConfig', { ip: ip }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.data;
  }
  catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export const updateServerConfig = async (ip, config) => {
  try {
    const response = await api.post('updateServerConfig', { ip: ip, config: config }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.data;
  }
  catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export const system = async () => {
  try {
    const response = await api.post('system', {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
};

// export const createCheckoutSession = async (lookup_key) => {
//   try {
//     const response = await api.post('igptCreateCheckoutSession', {lookup_key: lookup_key}, {
//       headers: {
//         'Authorization': `Bearer ${localStorage.getItem('jwt')}`
//       }
//     });
//     return response.data;
//   } catch (error) {
//     if (error.response) {
//       return error.response.data;
//     }
//     return error;
//   }
// };


export const createSubscription = async (plan_id) => {
  try {
    const response = await api.post('igptCreateSubscription', { plan_id: plan_id }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
};

export const verifyIntent = async (intent_id) => {
  try {
    const response = await api.post('verifyIntent', { intent_id: intent_id }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.data;
  }
  catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
};

export const getUserInvoices = async () => {
  try {
    const response = await api.post('getUserInvoices', {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.data;
  }
  catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export const swapSubscription = async (plan_id) => {
  try {
    const response = await api.post('swapSubscription', { plan_id: plan_id }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.data;
  }
  catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export const cancelSubscription = async () => {
  try {
    const response = await api.post('cancelSubscription', {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      }
    });
    return response.data;
  }
  catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}