// styles
import styles from './style.module.scss';

// hooks
import { useModal } from '@contexts/modalContext';


const ServerlistItem = ({ variant, servername, online, ip, gamemode, players, maxplayers }) => {
    const { handleOpen, setModalData } = useModal();

    return (
        variant === "newserver" ?
            <button onClick={() => handleOpen('server')} style={{ height: '80px'}} className={`card bordered`}>
                <div className={styles.container2}>
                    <div className="d-flex flex-row">
                        <i className={`icon icon-plus ${styles.sm}`} />
                        <h3 className="text-overflow" style={{ height: "100%", margin: "auto" }}>Whitelist a new server</h3>
                    </div>
                </div>
            </button>
            :
            <button onClick={() => { setModalData({ip: ip, name: servername}); handleOpen('serverOption');}} style={{ height: '80px' }} className={`card bordered`}>
                <div className={styles.container2}>
                    <div className="d-flex flex-row g-16">
                        <i className={`icon icon-lang ${styles.sm} ${online ? styles.accent : styles.red}`} />
                        <div className={`${styles.alignleft}`} >
                            <h3 className="">{servername}</h3>
                            <span>{ip}</span>
                        </div>
                    </div>
                    <div className="d-flex flex-column" style={{ }}>
                        <h3 className="">{gamemode}</h3>
                        <span >{players}/{maxplayers}</span>
                    </div>
                </div>
            </button>
    )
}

export default ServerlistItem