// styling
import styles from './style.module.scss';

// components
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { NavLink } from 'react-router-dom';
// hooks
import { useSidebar } from '@contexts/sidebarContext';
import { useWindowSize } from 'react-use';
import { useThemeProvider } from '@contexts/themeContext';
import { useModal } from '@contexts/modalContext';
import useAuthRoute from '@hooks/useAuthRoute';

// utils
import { memo } from 'react';

// constants
import LINKS from '@constants/links';

// assets
import logo from '@assets/logo-dark.svg';
import avatar from '@assets/avatar.png';
import { useAuth } from '@contexts/AuthContext';

const Sidebar = () => {
    const { open, setOpen } = useSidebar();
    const { handleOpen } = useModal();
    const { theme } = useThemeProvider();
    const isTemporary = useWindowSize().width < 1920;
    const isActive = (path) => window.location.pathname === path;
    const isAuthRoute = useAuthRoute();

    const { currentUser } = useAuth();

    return (
        <>
            {
                !isAuthRoute ? (
                    <SwipeableDrawer
                        anchor="left"
                        open={isTemporary ? open : true}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        variant={isTemporary ? 'temporary' : 'persistent'}
                        PaperProps={{
                            sx: {
                                width: 310,
                                padding: '32px 32px 40px',
                                backgroundColor: 'var(--widget)',
                                color: theme === 'light' ? 'var(--header)' : 'var(--text)',
                                fontSize: '1rem',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                height: '100%',
                                fontWeight: '600 !important'
                            }
                        }}>
                        <NavLink className={styles.logo} to="/">
                            <img src={logo} alt="logo" style={{ width: 33 }} />
                            <span className="h1">Insane GPT</span>
                        </NavLink>
                        <div className={`${styles.list} flex-1`}>
                            {LINKS.map(link => (
                                link.isBeta ?
                                    (
                                        <button key={link.desktop} className={`nav-link nav-link--sidebar wrapper ${styles.list_item_beta}`} onClick={() => handleOpen('beta')}>
                                            <span className='wrapper'>
                                                <i className={`icon icon-${link.icon === 'home' ? 'dashboard' : link.icon} ${link.icon === 'collections' ? styles.sm : ''} ${isActive(link.path) ? '' : 'active'}`} />
                                                <i className={`icon icon-fill ${isActive(link.path) ? 'active' : ''} icon-${link.icon === 'home' ? 'dashboard' : link.icon}${link.icon !== 'trade' ? '-active' : ''} ${link.icon === 'collections' ? styles.sm : ''}`} />
                                            </span>
                                            {link.desktop}
                                        </button>
                                    )
                                    :
                                    (
                                        <NavLink className={`nav-link nav-link--sidebar ${styles.list_item}`}
                                            key={link.desktop}
                                            to={link.path}>
                                            <span className='wrapper'>
                                                <i className={`icon icon-${link.icon_filled}`} />
                                                <i className={`icon icon-fill active icon-${link.icon}`} />
                                            </span>
                                            {link.desktop}
                                        </NavLink>
                                    )
                            ))}
                        </div>
                        <div className="d-flex flex-column g-40 w-100">
                            <div className={styles.list}>

                                <div className={`${styles.banner} card card--abstract`}>
                                    <h3 className={`${styles.banner_text} text-white text-large`}>
                                        Welcome on InsaneGPT
                                    </h3>
                                </div>
                            </div>
                            {currentUser && currentUser.func && currentUser.func === 'SYSOPERATOR'
                                ?
                                <>
                                    {/* <NavLink to={'/users'} className={`nav-link nav-link--sidebar wrapper ${styles.list_item}`}>
                                        <span className='wrapper'>
                                            <i className={`icon icon-aml`} />
                                            <i className={`icon icon-fill icon-aml active`} />
                                        </span>
                                        Users
                                    </NavLink> */}

                                    <NavLink to={'/users'} className={`nav-link nav-link--sidebar wrapper ${styles.list_item}`}>
                                        <span className='wrapper'>
                                            <i className={`icon icon-aml`} />
                                            <i className={`icon icon-fill icon-aml active`} />
                                        </span>
                                        Users
                                    </NavLink>

                                    <NavLink to={'/notifications'} key={'Notifications'} className={`nav-link nav-link--sidebar wrapper ${styles.list_item}`}>
                                        <span className='wrapper'>
                                            <i className={`icon icon-notification`} />
                                            <i className={`icon icon-fill icon-notification-active active`} />
                                        </span>
                                        Notifications

                                        {currentUser.notifications_read === 0
                                            ?
                                            <span className="indicator"></span>
                                            : null}

                                    </NavLink>
                                </>
                                :
                                null}
                            {currentUser ?
                                <div className="d-flex align-items-center g-16 text-header">
                                    <img className="avatar" src={avatar} alt={currentUser.name} />
                                    <button className="text-600" onClick={() => handleOpen('account')}>
                                        {currentUser.name}
                                    </button>
                                </div>
                                : null}
                        </div>
                    </SwipeableDrawer>
                ) : null
            }
        </>
    )

}

export default memo(Sidebar);