import styles from './style.module.scss';
import { useAuth } from '@contexts/AuthContext';

const TokenButton = ({ isMobile }) => {
const { currentUser } = useAuth();

    return (
        <button>
            <div className={"d-flex flex-column g-8 card " + styles.gradientborderdark} style={{ padding: 10, paddingLeft: 30, paddingRight: 30, }}>
                <p>Your tokens: <span className='h3'>{currentUser.wallet.tokens.toLocaleString()}</span></p>
            </div>
        </button>
    );
}

export default TokenButton