// components
import ModalContainer from '@components/ModalContainer';

// hooks
import { useModal } from '@contexts/modalContext';
import { useEffect, useState } from 'react';
import CenteredWrapper from './CenteredWrapper';
import { Dna } from 'react-loader-spinner';
import LabelField from './LabelField';
import { PatternFormat } from 'react-number-format';
import { addWhitelist } from 'api/apiHandler';
import { useAuth } from '@contexts/AuthContext';
import RangeSlider from '@ui/RangeSlider';
import Switch from '@ui/Switch';
import StyledSelect from '@ui/StyledSelect';
import euro from '@assets/icons/euro.svg';
import { getServerConfig, updateServerConfig } from 'api/apiHandler';
import { encode } from 'gpt-tokenizer';
import {FiDelete} from 'react-icons/fi'; 

const ServerOptionModal = () => {
    const { open, modal, handleClose, handleOpen, modalData } = useModal();
    const [inputValue, setInputValue] = useState('');
    const [subError, setSubError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { refreshData, currentUser } = useAuth();
    const [uur, setUur] = useState(5000);
    const [useCustomContext, setUseCustomContext] = useState(false);
    const [customContext, setCustomContext] = useState('');
    const [maxUserRequestLength, setMaxUserRequestLength] = useState(0);
    const [userRequestAutoTrim, setUserRequestAutoTrim] = useState(false);
    const [customContextCost, setCustomContextCost] = useState(0);

    const [isOptionUpdated, setIsOptionUpdated] = useState(false);

    const [MODELS, setMODELS] = useState([{ value: 'loading', label: 'loading', cost: 0 }]);

    const [model, setModel] = useState(MODELS[0]);

    useEffect(() => {
        setCustomContextCost(encode(customContext).length);
    }, [customContext]);

    useEffect(() => {
        if (open) {
            if (modal == 'serverOption') {
                setIsLoading(true);
                getServerConfig(modalData.ip).then(response => {
                    setIsLoading(false);
                    if (response.error) {
                        console.log("error");
                        setSubError(response.error);
                    }
                    else {
                        console.log("loading");
                        // create MODELS list from response knowing response.models is an array of objects object containing model_name and cost
                        let cMODELS = []
                        response.models.forEach(model => {
                            console.log("pushing: ", model);
                            cMODELS.push({ value: model.model_name, label: model.model_name, cost: model.cost })
                        });
                        setMODELS(cMODELS);

                        console.log("mdls:", cMODELS);

                        setUur(response.server.unique_user_rate);
                        setModel(cMODELS.find(m => m.value == response.server.model_name));
                        setUseCustomContext(response.server.use_custom_context);
                        setCustomContext(response.server.custom_context);
                        setMaxUserRequestLength(response.server.max_user_req_length);
                        setUserRequestAutoTrim(response.server.user_req_auto_trim);
                    }
                }
                ).catch(error => {
                    setIsLoading(false);
                    setSubError(error);
                })
            }
        }
    }, [open])

    const handleSubmit = (ip) => {
        setIsLoading(true);
        let data = {
            ip: modalData.ip,
            unique_user_rate: uur,
            model_name: model.value,
            use_custom_context: useCustomContext,
            custom_context: customContext,
            max_user_req_length: maxUserRequestLength,
            user_req_auto_trim: userRequestAutoTrim
        }
        updateServerConfig(modalData.ip, data).then(response => {
            setIsLoading(false);
            if (response.error) {
                setSubError(response.error);
            } else {
                setSubError('');
                handleClose();
            }
        }
        ).catch(error => {
            setIsLoading(false);
            setSubError(error);
        });

    }

    const handleRemove = () => {
        handleClose();
        handleOpen('removeServer');
    }

    // TODO: Check permissions for ai models

    return (

        <ModalContainer open={open && modal === 'serverOption'} handleClose={handleClose}>
            <div className="widget_body modal card d-flex flex-column" style={{ gap: 30 }}>
                <div className="d-flex flex-column g-8">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2 className="text-large">Server Options</h2>
                        <button className='d-flex flex-row align-items-center hover-red-i' onClick={handleRemove} > <span style={{fontSize:'16px'}}><FiDelete /></span></button>
                    </div>
                    <small><span className='text-overflow'>{modalData.name}</span></small>


                    {isLoading ? <CenteredWrapper height={'100%'}>
                        <Dna
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                        />
                    </CenteredWrapper> :
                        <div className="d-flex flex-column g-16" style={{ marginTop: '10px', marginBottom: '-10px' }}>
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className='text-white'> User Rate - <b>{uur}</b> tk / hour</span>
                                    <RangeSlider value={uur}
                                        onChange={e => setUur(e.target.value)}
                                        min={0}
                                        max={20000}
                                        step={500} />
                                </div>
                                <small>Single player can consume up to {uur} tk per hour</small>
                            </div>
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className='text-white'>AI Model</span>
                                    <StyledSelect value={model}
                                        onChange={setModel}
                                        options={MODELS}
                                        variant="minimal" />
                                </div>
                                <small>Model cost: {model.cost} tk / request</small>
                            </div>

                            {
                                currentUser.permissions.includes('customcontext') &&
                                (
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className='text-white'>Use custom context</span>
                                            <Switch id="customContext"
                                                checked={useCustomContext}
                                                onChange={() => { setUseCustomContext(!useCustomContext) }} />
                                        </div>
                                    </div>
                                )
                            }

                            {
                                currentUser.permissions.includes('customcontext') &&
                                (
                                    <div className='' style={useCustomContext ? {} : { opacity: '20%' }}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className='text-white pr-16'>Custom context</span>
                                            {
                                                useCustomContext ? <LabelField autocomplete="off" id="custom-context"
                                                    placeholder={'Paste custom context here'}
                                                    value={customContext}
                                                    onChange={(event) => setCustomContext(event.target.value)} />
                                                    : <span className='text-white' style={{ paddingBottom: '17px', paddingTop: '17px' }}>Disabled</span>}
                                        </div>
                                        <small>Context cost: {customContextCost} tk / request</small>
                                    </div>
                                )
                            }
                            <div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className='text-white'>Max output length - <b>{maxUserRequestLength}</b> chars</span>
                                    <RangeSlider value={maxUserRequestLength}
                                        onChange={e => setMaxUserRequestLength(e.target.value)}
                                        min={0}
                                        max={4000}
                                        step={50} />
                                </div>
                                <small>The maximum length in characters that a player's message can be</small>
                            </div>
                            {
                                currentUser.permissions.includes('trim') &&
                                (
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className='text-white'>Trim whitespaces</span>
                                            <Switch id="trim-whitespaces"
                                                checked={userRequestAutoTrim}
                                                onChange={() => { setUserRequestAutoTrim(!userRequestAutoTrim) }} />
                                        </div>
                                        <small>All output messages will be trimmed to save tokens: 'This&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;message' will become 'This message'</small>
                                    </div>
                                )
                            }

                            {/* <div className="d-flex justify-content-between align-items-center text-uppercase">
                                <span> Unique User Rate - <span className='h4'>{temperal}</span> / hour</span>
                                <Switch id="theme"
                                    checked={true}
                                    onChange={() => { }} />
                            </div> */}
                            {/* MODEL TEMPERATURE */}
                            {/* <div className="d-flex justify-content-between align-items-center text-uppercase">
                                <span> Model Temperature - <span className='h4'>{temperal}</span></span>
                                <RangeSlider value={temperal}
                                    onChange={e => setTemp(e.target.value)}
                                    min={0}
                                    max={1}
                                    step={0.01} />
                            </div> */}
                        </div>}
                    <small style={{ color: 'var(--red)' }}>{subError}</small>
                </div>
                {
                    !isLoading &&
                    <div className="d-grid col-2 g-16">
                        <button className="btn btn--invert" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="btn" onClick={handleSubmit}>Save</button>
                    </div>
                }

            </div>
        </ModalContainer>
    )
}

export default ServerOptionModal