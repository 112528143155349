// components
import AppLayout from '@components/AppLayout';
import PageHeader from '@components/PageHeader';
import AppGrid from '@components/AppGrid';
import UsersList from '@widgets/UsersList';


const Users = () => {

    const widgets = {
        users: <UsersList/>,
    }

    return (
        <AppLayout>
            <PageHeader title="Users" variant="dashboard" />
            <AppGrid id="users" widgets={widgets} />
        </AppLayout>
    )
}

export default Users