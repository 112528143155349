// styles
import styles from './style.module.scss';
import Tilt from 'react-parallax-tilt';

// hooks
import { useModal } from '@contexts/modalContext';
import { useAuth } from '@contexts/AuthContext';
import { useEffect, useState } from 'react';
import Loading from '@pages/Loading';
import { NavLink, Navigate } from 'react-router-dom';

import green from '@assets/offer-g.png';
import gray from '@assets/offer-d.png';
import purple from '@assets/offer-p.png';
import blue from '@assets/offer-b.png';


const Package = ({ sub, features }) => {
    const { handleOpen, setModalData } = useModal();
    
    return (
        <div
            className={`${styles.container} widget_body card`}
            style={{
                backgroundColor: '#252525',
                borderRadius: '10px',
                width: '300px',  // Fixed width for inner card
                position: 'absolute',
                overflow: 'hidden',     // This will hide any overflowing child content
                top: '0',      // This positions the inner card at the top of the parent card; adjust as needed
                right: '0',   // This sticks the inner card to the right side of the parent card
            }}>
            {/* Inner right card content */}

            <img src={sub.id == 1 ? gray : sub.id == 2 ? blue : sub.id == 3 ? green : purple} alt=""
                style={{
                    transform: 'scale(1.3)',   // Make the image larger than its parent
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    height: '200px',
                }} />
            <div style={{ backgroundColor: "#202020", width: "250px", height: '60px', position: 'absolute', borderRadius: '5px', top: '200px', left: '25px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', }}>
                <div className={`${styles.container} d-flex flex-row justify-content-between align-items-center g-8 p-16`}>
                    <h3>{sub.name} plan</h3>
                    <h4>€{sub.price} / month</h4>
                </div>
            </div>
            <h2 style={{ position: 'absolute', right: '7%' }}>{sub.name}</h2>

            <div
                className="d-flex flex-column justify-content-between align-items-center g-8"
                style={{ width: '100%', height: '100%' }}>

                <div style={{ width: '100%', height: '90%' }}></div>
                <div style={{ width: '100%', height: '100%', pointerEvents: 'none' }}
                    className='d-flex flex-column pl-24 g-8'
                >
                    {features.INCLUDED.map((feature, index) => (
                        <span key={index} className='d-flex flex-row align-items-center g-8'><h3 style={{ fontWeight: 'lighter' }}>{feature}</h3></span>
                    ))}
                    {features.MISSING.map((feature, index) => (
                        <span key={index} className='d-flex flex-row align-items-center g-8'><i className='icon icon-xmark'></i><h3 style={{ fontWeight: 'lighter', color: 'var(--text-light)' }}>{feature}</h3></span>
                    ))}
                </div>
            </div>

            <button onClick={() => handleOpen('beta')} style={{ backgroundColor: "#282828", width: "250px", height: '60px', position: 'absolute', borderRadius: '5px', bottom: '16px', left: '25px', zIndex: 2, }}>
                <div className={`${styles.container} d-flex flex-row justify-content-center align-items-center g-8 p-16`}>
                    <i className='icon icon-chevron-right h3' style={{ fontWeight: 'normal', fontSize: '1.2em' }} /> <h3>Check the features FAQ</h3>
                </div>
            </button>
        </div>


    )
}


export default Package